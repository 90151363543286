import React from "react";
import { Row, Col, Container } from "reactstrap";
import Mint from "../../components/Mint"


const FAQ = () => {
    return (
        <section className="px-0" id='faqs'>
            <Container>
                <Row>
                    <Col xl="3">
                        <h1 className='text-white sub-header text-uppercase mb-0'>FAQS</h1>
                    </Col>
                    <Col xl="3" className="mt-4 mt-xl-0 ">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">How do I purchase NFTs from David Beloved collection?</p>
                            <ul className="pt-3 pl-3">
                                <li className="text-uppercase text-white ff-satoshi-regular">Create an account on the NFT platform hosting David Beloved collection.</li>
                                <li className="text-uppercase text-white ff-satoshi-regular">Connect your digital wallet</li>
                                <li className="text-uppercase text-white ff-satoshi-regular">Browse the available NFTs, select the one you want, and follow the purchase instructions</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4 mt-xl-0 ">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">How are royalties distributed to NFT holders?</p>
                            <p className="text-uppercase text-white ff-satoshi-regular pt-3 ">Royalties are automatically distributed to NFT holders whenever the NFT is resold. This means that you'll earn a percentage of the sale price every time your NFT changes hands, as per the terms of the smart contract
                            </p>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4 mt-xl-0 ">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">Can I listen to the exclusive music as an NFT holder?</p>
                            <p className="text-uppercase text-white ff-satoshi-regular pt-3 ">Yes, as an NFT holder, you'll have access to the exclusive music tracks associated with the NFTs you own. You can enjoy these tracks and experience the unique artistry of David Beloved.</p>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">What makes David Beloved NFT collection different from others?</p>
                            <p className="text-uppercase text-white ff-satoshi-regular pt-3 ">David Beloved collection stands out for its blend of music and art. Each NFT is a piece of art that also grants access to exclusive music. You become a part of a community, enjoying true ownership and royalties.</p>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">What makes David Beloved NFT collection different from others?</p>
                            <p className="text-uppercase text-white ff-satoshi-regular pt-3 ">David Beloved collection stands out for its blend of music and art. Each NFT is a piece of art that also grants access to exclusive music. You become a part of a community, enjoying true ownership and royalties.</p>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4">
                        <div className="bg-faqs p-4">
                            <p className="text-uppercase ff-grotesk-semibold text-white border-bottom pb-3">Are there any upcoming releases or events for NFT holders?</p>
                            <p className="text-uppercase text-white ff-satoshi-regular pt-3 ">Absolutely! We have a vibrant roadmap that includes new NFT drops, collaborations, and exclusive events for NFT holders. Stay connected to be the first to know about these exciting developments.</p>
                        </div>
                    </Col>
                    <Col xl="3" className="mt-4 mt-xl-0 d-flex flex-column align-items-start justify-content-center">
                        <h2 className="text-white text-uppercase ff-grotesk-semibold mb-2">Join the Community and Get Answers</h2>
                        <Mint />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FAQ