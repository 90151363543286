import React from 'react'
import Carousel, { consts } from 'react-elastic-carousel'
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather'
import { Row, Col, Container, Button } from 'reactstrap'
import Mint from "../../components/Mint"

const NFT_Collection = () => {
    const breakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 450, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 650, itemsToShow: 3 },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 }
    ]

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowLeftCircle /> : <ArrowRightCircle />
        return (
            <Button onClick={onClick} disabled={isEdge}>{pointer}</Button>
        )
    }
    return (
        <section className='px-0 nft-collection' id='nfts'>
            <Container>
                <h1 className='text-white sub-header text-center text-uppercase mb-2'>Explore the NFT</h1>
                <h1 className='text-white sub-header text-center text-uppercase mb-0'>Collection</h1>
                <Carousel
                    renderArrow={myArrow}
                    breakPoints={breakpoints}
                    pagination={false}
                    itemPadding={[30, 30, 30, 30]}
                >
                    <img src={require('../../assets/img/Landing_Page/nfts/1.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/2.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/3.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/4.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/5.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/6.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/7.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/8.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/9.gif').default} alt="PRINCE_RA" className="img-fluid" />
                    <img src={require('../../assets/img/Landing_Page/nfts/10.gif').default} alt="PRINCE_RA" className="img-fluid" />
                </Carousel>
                <Row className='align-items-center'>
                    <Col sm="6" lg="4" className='d-flex align-items-center align-items-sm-start flex-column'>
                        <h3 className='mb-0'>10 Exclusive NFTs</h3>
                        {/* <img src={require('../../assets/img/Landing_Page/blue1000+.png').default} alt="PRINCE_RA" className="img-fluid w-25" /> */}
                        <p className="text-uppercase ff-satoshi-bold text-white mt-3">Explore David Beloved mesmerizing NFT collection. Get a sneak peek at stunning NFTs and their stories. Click to see more!</p>
                    </Col>
                    <Col lg="4" className='d-none d-lg-block'>
                    </Col>
                    <Col sm="6" lg="4" className='text-sm-right text-center'>
                        <Mint />
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col lg="12" className='position-rel'>
                        <img src={require('../../assets/img/Landing_Page/mic.png').default} alt="PRINCE_RA" className="img-fluid w-100" />
                        <div className='text-center play-btn-position'>
                            <img src={require('../../assets/img/Landing_Page/play.png').default} alt="PRINCE_RA" className="img-fluid w-25" />
                            <p className='ff-cheri-regular font-80'>David Beloved</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NFT_Collection