import { Row, Col } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Mint from "components/Mint"
export default function Banner() {

  return (
    <div className="bg-banner">
      <div className="container">
        <div>
          <IndexNavbar />
        </div>
        <div className="py-5 position-rel">
          <h1 className="ff-grotesk-semibold">DBNFT.AI</h1>
          <Row>
            <Col md="4" className="d-flex flex-column justify-content-between">
              <p className="text-uppercase ff-satoshi-bold text-white">Step into David Beloved world, where music defies boundaries. Join a unique journey where every note is an NFT, and every collector is part of a groundbreaking movement</p>
              <div className="text-center text-md-left">
                <img src={require('../../assets/img/Landing_Page/banner-arrow.png').default} alt="PRINCE_RA" className="img-fluid mt-5" />
              </div>
            </Col>
            <Col md="4">
              {/* <video className="img-fluid">
                <source src={require('../../assets/img/Landing_Page/prid').default} type="video/mp4" />
              </video> */}
              <img src={require('../../assets/img/Landing_Page/pridegif.gif').default} alt="PRINCE_RA" className="img-fluid" />
            </Col>
            <Col md="4" className="mt-4 mt-md-0 d-flex flex-column justify-content-between">
              <div className="text-center text-md-left">
                <img src={require('../../assets/img/Landing_Page/nft.png').default} alt="PRINCE_RA" className="img-fluid ml-md-5 position-nft" />
              </div>
              <div className="mt-4 mt-md-0">
                <p className="text-uppercase ff-satoshi-bold text-white">Join the music revolution. Own exclusive tracks, connect with fellow enthusiasts. It's more than NFTs; it's a movement.</p>
                <Mint />
              </div>
            </Col>
          </Row>
          <Row className="mt-5 border d-flex align-items-center">
            <Col md="5" className="pl-0 d-flex align-items-center border-right">
              <img src={require('../../assets/img/Landing_Page/unique-nft.png').default} alt="PRINCE_RA" className="w-75" />
              <h1 className="nft-no" style={{ color: 'white' }}>10</h1>
              {/* <img src={require('../../assets/img/Landing_Page/1000+.png').default} alt="PRINCE_RA" className="nft-no" /> */}
            </Col>
            <Col md="7" className="unique-nft text-center">
              <p className="text-white ff-grotesk-semibold unique-text">UNIQUE <span className="text-blue">NFTS</span></p>
            </Col>
          </Row>
        </div>
      </div>
    </div>

  )
}