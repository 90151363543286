/*!

=========================================================
* PRINCE_RA - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
import { isMobile } from "../../helper/index";
import { useWeb3React } from '@web3-react/core';
import WalletDropdown from "components/ConnectWallet/WalletDropdown";
import connectors from "../../helper/walletConnector"
import activateInjectedProvider from "../../helper/walletHelp"
import ConnectWallet from "components/ConnectWallet";
// reactstrap components
import {

  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col
} from "reactstrap";
import { FiMenu } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa'

export default function IndexNavbar() {

  const { account, active, activate } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  useEffect(() => {
    const provider = localStorage.getItem("provider");
    if (provider) {
      if (provider === 'injected') {
        if (!isMobile() && !window.ethereum) {
          window.open('https://metamask.io/download/', '_blank');
        } else {
          activateInjectedProvider('MetaMask');
          activate(connectors.injected);
        }
      } else activate(connectors[provider]);
    }

  }, []);

  return (
    <>
      <Navbar expand="lg" className="bg-nav px-0">
        <Row className="w-100">
          <Col lg="2" className="px-0">
            <div>
              <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                <h3 className="ff-grotesk-semibold text-white mt-3 mb-2">DBNFT.AI</h3>
              </NavbarBrand>
              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-white" size="24" />
              </button>
            </div>
          </Col>
          <Col lg="10" className="d-flex justify-content-end pr-0">
            <Collapse
              className={"ml-4 justify-content-end " + collapseOut}
              navbar
              isOpen={collapseOpen}
              onExiting={onCollapseExiting}
              onExited={onCollapseExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <img src={require('../../assets/img/logo.png').default} alt='PRINCE_RA' className='img-fluid' />
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button
                      aria-expanded={collapseOpen}
                      className="navbar-toggler"
                      onClick={toggleCollapse}
                    >
                      <FaTimes className="pf-text-secondary" size="24" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar className="nav-menu p-1">
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/" rel='noreferrer ' className="text-white ff-clash-semibold">HOME</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#nfts" rel='noreferrer' className="text-white ff-clash-semibold">NFTs</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#ROADMAP" rel='noreferrer' className="text-white ff-clash-semibold">ROADMAP</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#faq" rel='noreferrer' className="text-white ff-clash-semibold">FAQS</a>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className=" py-1 ml-lg-2 mt-2 mt-lg-0">
                {(!active) &&
                  <button className="button-50 ff-clash-bold mx-0" onClick={() => setIsOpen(true)}>CONNECT WALLET</button>
                }
                {active && account &&
                  <WalletDropdown />
                }

              </div>
            </Collapse>
          </Col>
        </Row>
      </Navbar>
      <ConnectWallet setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
}
