import React, { useState } from 'react'
import { ThumbsUp } from 'react-feather'
import { FaPause, FaPlay, FaSpotify } from 'react-icons/fa'
import { FiShare2 } from 'react-icons/fi'
import { Col, Container, Row, Table } from 'reactstrap'
import { RiFolderDownloadLine } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";

const Listen_music = () => {

    const [active, setActive] = useState(1)

    const data = [
        {
            id: 1,
            name: "Beloved's Completion Master 24",
            audioFile: require('../../assets/audio/audio1.mp3').default,
            duration: '06:47'
        },
        {
            id: 2,
            name: "Beloveds-Arrival-Transmission-Ma",
            audioFile: require('../../assets/audio/audio2.mp3').default,
            duration: '04:52'
        },
        {
            id: 3,
            name: "The-Sound-Traveler-Master-24",
            audioFile: require('../../assets/audio/audio3.mp3').default,
            duration: '05:21'
        },
        {
            id: 4,
            name: "The-Universe-Is-Loving-On-Me-Mas",
            audioFile: require('../../assets/audio/audio4.mp3').default,
            duration: '04:23'
        },
        {
            id: 5,
            name: "Turn-It-Around-Vives-Master-24",
            audioFile: require('../../assets/audio/audio5.mp3').default,
            duration: '04:51'
        },
        {
            id: 6,
            name: "You're Blinded By Lies Lower Frequencies",
            audioFile: require('../../assets/audio/audio6.mp3').default,
            duration: '04:58'
        },
        {
            id: 7,
            name: "The Greatest Hoax master 2024",
            audioFile: require('../../assets/audio/audio7.mp3').default,
            duration: '06:43'
        },
        {
            id: 8,
            name: "The First Last The Last First Master 24",
            audioFile: require('../../assets/audio/audio8.mp3').default,
            duration: '05:52'
        },
        {
            id: 9,
            name: "Setting The Tone New Energy Master 24",
            audioFile: require('../../assets/audio/audio9.mp3').default,
            duration: '05:42'
        },
        {
            id: 10,
            name: "My Frequency Vibes Higher",
            audioFile: require('../../assets/audio/audio10.mp3').default,
            duration: '05:39'
        },
        {
            id: 11,
            name: "Get The Coin $ Doe Arc Anthem Master 24",
            audioFile: require('../../assets/audio/audio11.mp3').default,
            duration: '04:02'
        },
        {
            id: 12,
            name: "Eyes Blinded By Lies Master 24",
            audioFile: require('../../assets/audio/audio12.mp3').default,
            duration: '05:13'
        },
        {
            id: 13,
            name: "13 Frequencies to El Master 24",
            audioFile: require('../../assets/audio/audio13.mp3').default,
            duration: '05:03'
        },
        {
            id: 14,
            name: "Currency Flowing in Ur Viens Master 24",
            audioFile: require('../../assets/audio/audio14.mp3').default,
            duration: '05:40'
        },
    ]

    const handlePlay = (i) => {
        setActive(i.id)
        const temp = data.filter((item, index) => item.id !== i.id).map((item) => {
            var audioId = document.getElementById(`myAudio${item.id}`)
            const play = document.getElementById(`play-icon${item.id}`)
            const pause = document.getElementById(`pause-icon${item.id}`)
            play.classList.remove('d-none')
            pause.classList.add('d-none')
            return audioId.pause()
        })
        var audio = document.getElementById(`myAudio${i.id}`)
        const play = document.getElementById(`play-icon${i.id}`)
        const pause = document.getElementById(`pause-icon${i.id}`)
        play.classList.add('d-none')
        pause.classList.remove('d-none')
        audio.play()

        audio.addEventListener('ended', () => {
            play.classList.remove('d-none');
            pause.classList.add('d-none');
        });
    }

    const handlePause = (i) => {
        var audio = document.getElementById(`myAudio${i.id}`)
        const play = document.getElementById(`play-icon${i.id}`)
        const pause = document.getElementById(`pause-icon${i.id}`)
        play.classList.remove('d-none')
        pause.classList.add('d-none')
        audio.pause()
    }

    const listenSpotify = () => {
        window.open('https://ditto.fm/13teen-frequencies-2-elevate', '_blank')
    }

    return (
        <div className='bg-right-music-section'>
            <section className='px-0'>
                <Container>
                    <Row className='d-flex align-items-center border-bottom pb-3'>
                        <Col lg="6">
                            <h1 className='text-white sub-header text-uppercase mb-0'>Listen music</h1>
                        </Col>
                        <Col lg="6" className='text-right  d-flex justify-content-end'>
                            <button className="btn bg-listen-btn text-white d-flex align-items-center ff-GT-bold mx-0 text-uppercase" onClick={() => listenSpotify()}><span>Listen to full album on</span> <img className="ml-2" width="25" src={require('../../assets/img/Landing_Page/pride4.png').default} /> </button>
                        </Col>
                    </Row>
                    <div className='position-rel'>
                        <Row className='mt-5 d-flex'>
                            <Col lg="6" className=''>
                                <img src={require('../../assets/img/Landing_Page/pride4.png').default} alt="PRINCE_RA" className="h-100 img-fluid" />
                            </Col>
                            <Col lg="6" className=''>
                                <p className='text-white ff-satoshi-bold'>&#8725;&#8725; Album</p>
                                <h1 className='ff-grotesk-semibold text-white text-uppercase'>Always Authentic</h1>
                                <h3 className='text-white ff-satoshi-bold text-uppercase mt-5'>David Beloved</h3>
                                <p className='ff-satoshi-regular text-uppercase'>The artists we represent are one of the most successful in Virginia and also were a huge breakthrough in the international market, topping radio and sales around the world.</p>
                                <Table className="mb-0 mt-5 listen-list">
                                    <tbody>
                                        {
                                            data && data.length > 0 && data.map((item, index) => {
                                                return <tr className={active === item.id ? "bg-active" : "bg-trasparent"}>
                                                    <th className='d-none cursor-pointer' id={`pause-icon${item.id}`}><FaPause color='black' onClick={() => handlePause(item)} /></th>
                                                    <th className='cursor-pointer' id={`play-icon${item.id}`}><FaPlay color='white' onClick={() => handlePlay(item)} /></th>
                                                    <audio id={`myAudio${item.id}`}>
                                                        <source src={item.audioFile} type="audio/mp3" />
                                                    </audio>
                                                    <th className="project-width"><ThumbsUp size={20} color={active === item.id ? 'black' : 'white'} /> </th>
                                                    <th className={active === item.id ? 'text-dark ff-satoshi-regular' : 'text-white ff-satoshi-regular'}>{item.name}</th>
                                                    <th className={active === item.id ? 'text-dark ff-satoshi-regular' : 'text-white ff-satoshi-regular'}>67,420</th>
                                                    <th className={active === item.id ? 'text-dark ff-satoshi-regular' : 'text-white ff-satoshi-regular'}>{item.duration}</th>
                                                    <th><RiFolderDownloadLine color={active === item.id ? 'black' : 'white'} size={20} /> </th>
                                                    <th><FiShare2 color={active === item.id ? 'black' : 'white'} /> </th>
                                                    <th><HiDotsHorizontal color={active === item.id ? 'black' : 'white'} /> </th>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {/* <div className='position-coming-text text-center'>
                            <h2 className='text-white sub-header text-uppercase mb-0'>COMING SOON</h2>
                        </div> */}
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default Listen_music