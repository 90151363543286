import React, { Fragment, useState, useEffect } from "react"
import { Button, Col, Row, Modal, ModalBody } from "reactstrap"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { mintNFT, isActiveSale } from "utils/interact";
import ConnectWallet from "components/ConnectWallet";
import { useWeb3React } from '@web3-react/core'
import { useAlert } from "react-alert";
import Web3 from "web3"
import { ethers } from "ethers";
import { Minus, MinusSquare, PlusSquare } from "react-feather";

require('dotenv').config()

const BannerSection = () => {

    const alert = useAlert()
    const { active, account } = useWeb3React()
    let provider = localStorage.getItem('provider')

    const RpcHttpUrl = process.env.REACT_APP_RPC_HTTP_URL;

    const web3 = new Web3(new Web3.providers.HttpProvider(RpcHttpUrl));

    const [payModalOpen, setPayModalOpen] = useState(false);
    const [countModalOpen, setCountModalOpen] = useState(false);
    const [showWinter, setShowWinter] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [typeSelected, setTypeSelected] = useState(null);
    const [count, setCount] = useState(1);

    const getAssetData = () => {

        if (!active && account === undefined && provider === null) {
            setIsOpen(true)
            return false
        }

        setCountModalOpen(!countModalOpen)
    }

    const walletMint = async () => {

        setCountModalOpen(!countModalOpen)

        if (account) {

            const chainId = await window.ethereum.request({ method: 'eth_chainId' })

            if (chainId !== '0x1') {
                const hexChainId = ethers.utils.hexValue(1)

                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: hexChainId }]
                    });

                } catch (switchError) {
                    if (switchError.code === 4902) {
                        console.log("This network is not available in your metamask, please add it")
                    }
                    console.log("Failed to switch to the network")
                }
                return false
            }

            const isSale = await isActiveSale()
          
            if (isSale) {

                const response = await mintNFT(count)

                if (response && response.success === true) {
                    alert.info("In Progress", { timeout: 0 })
                    const interval = setInterval(() => {
                        web3.eth.getTransactionReceipt(response.hash, (err, res) => {
                            if (res) {
                                clearInterval(interval)
                                alert.removeAll()
                                if (res.status === true) {
                                    alert.success("Successful!", { timeout: 5000 })
                                } else {
                                    alert.error("Failed!", { timeout: 5000 })
                                }
                            }
                        });
                    }, 4000);

                    setTypeSelected(null)
                    setIsOpen(false)
                } else {
                    alert.removeAll()
                    alert.error("Failed!", { timeout: 5000 })
                }

            } else {
                alert.error("Mint is not available now!", { timeout: 5000 });
                return false
            }
        }

        setCountModalOpen(!countModalOpen)

    }

    return (
        <Fragment>

            <div className="text-center mt-3"><button className="button-50 ff-GT-bold mt-3 px-5 mx-0" onClick={() => getAssetData()}>MINT NOW</button></div>

            <Modal isOpen={payModalOpen} toggle={() => setPayModalOpen(!payModalOpen)} size="sm" >
                <ModalBody className="mt-30 mb-30 wallets-modal">
                    <div className="closeButton float-right pb-5" onClick={() => setPayModalOpen(!payModalOpen)}><AiOutlineCloseCircle size={30} color="white" className='pointer' /></div>
                    <Row>
                        <Col md='12'>
                            <div className='px-2 py-4 text-center'>
                                <div className='wallet-wrapper text-center'>
                                    <div className='wallet-item px-0 d-flex align-items-center justify-content-center wal-button'>
                                        <img src={require(`../../assets/img/credit-card.png`).default} width={50} className='img-fluid' alt="" />
                                        <div className='wallet-name ml-0' onClick={() => { getAssetData('card') }}>Mint with Credit Card</div>
                                    </div>
                                    <div className='wallet-item d-flex align-items-center justify-content-center wal-button mt-4'>
                                        <img src={require(`../../assets/img/walletConnect.svg`).default} width={50} className='img-fluid' alt="" />
                                        <div className='wallet-name ml-0' onClick={() => { getAssetData('wallet') }}>Mint with Wallet</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={countModalOpen} toggle={() => setCountModalOpen(!countModalOpen)} size="sm" >
                <ModalBody className="mt-30 mb-30 wallets-modal">
                    <div className="closeButton cursor-pointer float-right pb-5" onClick={() => setCountModalOpen(!countModalOpen)}><AiOutlineCloseCircle size={30} color="white" className='pointer' /></div>
                    <Row>
                        <Col md='12'>
                            <div className='px-2 py-4 text-center'>
                                <h4 className="mm-regular text-dark">How many would you like to mint?</h4>
                                <div className="d-flex align-items-center justify-content-center text-center mb-3">
                                    <div className="cursor-pointer" onClick={() => { setCount((n) => n > 1 ? n - 1 : 1); }}><MinusSquare size={30} /></div>
                                    <input className="input-count form-control" placeholder={1} min={1} max={10} readOnly value={count} />
                                    <div className="cursor-pointer" onClick={() => { setCount((n) => n < 10 ? n + 1 : 10); }}><PlusSquare size={30} /></div>
                                </div>

                                <div className='wallet-item d-flex align-items-center justify-content-center wal-button mt-4'>
                                    <button className='button-50 ff-clash-bold mx-0' onClick={() => { walletMint() }}>SUBMIT</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <ConnectWallet setIsOpen={setIsOpen} isOpen={isOpen} />
        </Fragment >
    )
}
export default BannerSection