import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Deep_Rooted = () => {
    return (
        <section className='px-0 bg-health'>
            <Container>
                <Row className='align-items-center'>
                    <Col md="6" lg="6" className='mt-4 mt-md-0'>
                        <img src={require('../../assets/img/Landing_Page/real_music.jpeg').default} alt="PRINCE_RA" className="img-fluid" />
                    </Col>
                    <Col md="6" lg="6" className='mt-4 mt-md-0'>
                        <img src={require('../../assets/img/Landing_Page/drp_production.jpeg').default} alt="PRINCE_RA" className="img-fluid" />
                        {/* <h1 className='text-white sub-header text-uppercase'>DEEP ROOTED PRODUCTIONS</h1>
                        <p className="text-uppercase ff-satoshi-bold text-violet mb-4">Music Videos, Production, Lighting, Editing.</p> */}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Deep_Rooted