import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Beanoredh_Studios = () => {
    return (
        <section className='px-0 bg-health'>
            <Container>
                <Row className='align-items-center'>
                    <Col md="12" lg="12" className='mb-5'>
                        <video width={"100%"} controls>
                            <source src={require('../../assets/img/Landing_Page/studiored.mp4').default} type="video/mp4" />
                        </video>
                    </Col>
                    {/* <Col md="7" lg="6">
                        <h1 className='text-white sub-header text-uppercase'>BEANOREDH STUDIOS</h1>
                        <p className="text-uppercase ff-satoshi-bold text-violet mb-4">Mixing, Mastering, Songwriting.</p>
                    </Col>
                    <Col md="5" lg="6" className='mt-4 mt-md-0'>
                        <img src={require('../../assets/img/Landing_Page/real_music.jpeg').default} alt="PRINCE_RA" className="img-fluid" />
                    </Col> */}
                </Row>
                <Row className='align-items-center mt-5'>
                    <img src={require('../../assets/img/Landing_Page/flyer.jpg').default} alt="PRINCE_RA" className="img-fluid" />
                </Row>
            </Container>
        </section>
    )
}

export default Beanoredh_Studios