import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Mint from "../../components/Mint"

const Health_Wealth = () => {
    return (
        <section className='px-0 bg-health'>
            <Container>
                <Row className='align-items-center'>
                    <Col md="6" lg="6">
                        <h1 className='text-white sub-header text-uppercase'>Health &amp; wellness + LIVE COACHING</h1>
                        <p className="text-uppercase ff-satoshi-bold text-violet mb-4">Welcome to the world of David Beloved, the visionary rapper who's redefining the music industry. With a unique blend of talent, creativity, and innovation, David Beloved has set out on a mission to revolutionize how music is shared and experienced.</p>
                        <p className="text-uppercase ff-satoshi-bold text-violet">Discover the man behind the music, his incredible journey, and the inspiration that drives his artistry. From his early roots in the world of hip-hop to his cutting-edge approach to NFTs, David Beloved is committed to pushing the boundaries of what's possible.</p>
                        <Mint />
                    </Col>
                    <Col md="3" lg="3" className='mt-4 mt-md-0'>
                        <img src={require('../../assets/img/Landing_Page/live.jpeg').default} alt="PRINCE_RA" className="img-fluid" />
                    </Col>
                    <Col md="3" lg="3" className='mt-4 mt-md-0'>
                        <img src={require('../../assets/img/Landing_Page/live_coaching.jpeg').default} alt="PRINCE_RA" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Health_Wealth