/*!

=========================================================
* PRINCE_RA - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
  Row,
  Col,
  Container
} from "reactstrap";

import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";

export default function Footer() {
  return (
    <section className="bg-footer">
      <Container>
        <div className="container border-bottom-style">
          <Row className="pb-3 pb-md-5 text-center">
            <Col md="3" sm="12" xs="12" className="pl-0 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-md-start">
              <a href="/" className="d-flex flex-row justify-content-center justify-content-md-start">
                <img src={require('../../assets/img/Landing_Page/gold.png').default} alt="PRINCE_RA" className="img-fluid w-25" />
                <h3 className="ff-grotesk-semibold text-white mt-3 mb-2">DBNFT.AI</h3>
              </a>
            </Col>
            <Col md="6" sm="12" xs="12" className="mt-3 mt-md-0 d-flex align-items-center justify-content-center">
              <div className="footer-text d-flex flex-column flex-md-row text-primary">
                <a href="/" target={'_blank'} rel='noreferrer' className="mr-md-3 ff-clash-semibold text-white">HOME</a>
                <a href="/#nfts" rel='noreferrer' className="mr-md-3 ff-clash-semibold text-white">NFTs</a>
                <a href="/#roadmap" rel='noreferrer' className="mr-md-3 ff-clash-semibold text-white">ROADMAP</a>
                <a href="/#faq" rel='noreferrer' className="ff-clash-semibold text-white">FAQ</a>
              </div>
            </Col>
            <Col md="3" sm="12" xs="12" className="pr-0 mt-3 mt-md-0">
              <div className="social-div text-right d-flex flex-row justify-content-center justify-content-md-end">
                <a href="https://discord.gg/ptMaY693Pj" target={'_blank'} rel='noreferrer'><FaFacebook size="25" color="black" className="icon-rounded" /></a>
                <a href="https://twitter.com/PRINCE_RAFunds" target={'_blank'} rel='noreferrer'><FaTwitter size="25" color="black" className="icon-rounded" /></a>
                <a href="https://www.instagram.com/PRINCE_RAfunds/" target={'_blank'} rel='noreferrer'><FaInstagram size="25" color="black" className="icon-rounded" /></a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between mt-3 mt-md-5">
          <div className="text-center text-md-left ff-grotesk-semibold text-white text-uppercase">© 2023 David Beloved. All rights reserved.</div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0">
            <p className="ff-grotesk-semibold text-white text-uppercase mr-4">Privacy Policy</p>
            <p className="ff-grotesk-semibold text-white text-uppercase">Terms of use</p>
          </div>
        </div>
      </Container>
    </section >
  );
}
