import React from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { MdLocationOn } from "react-icons/md";
import Carousel, { consts } from 'react-elastic-carousel'
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather'

const Recent_Concert = () => {
    const breakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 450, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 650, itemsToShow: 3 },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 }
    ]

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowLeftCircle /> : <ArrowRightCircle />
        return (
            <Button onClick={onClick} disabled={isEdge}>{pointer}</Button>
        )
    }
    return (
        <section className='px-0'>
            <Container>
                <Row>
                    <Col lg="12">
                        <h1 className='text-white text-center sub-header text-uppercase mb-0'>UPCOMING CONCERTS</h1>
                    </Col>
                </Row>
                <div className='position-rel'>
                    <div className='mt-5 concert-img bg-hide-listen-music'>
                        <Carousel
                            renderArrow={myArrow}
                            breakPoints={breakpoints}
                            pagination={false}
                        >
                            <div className='position-rel'>
                                <img src={require('../../assets/img/Landing_Page/image_2.png').default} alt="PRINCE_RA" className="img-fluid" />
                                <div className='date-box px-2'>
                                    <h3 className='text-dark ff-grotesk-semibold mb-0'>21</h3>
                                    <p className='text-dark ff-grotesk-semibold mb-0'>NOV</p>
                                </div>
                                <div className='book-ticket'>
                                    <p className='text-white text-uppercase ff-satoshi-bold'>when we were young</p>
                                    <p className='d-flex align-items-center text-white text-uppercase ff-satoshi-regular mb-0'><MdLocationOn color='white' size={25} /> <span>wembley stadium uk</span></p>
                                    <button className="button-50 ff-GT-bold mt-3 mx-0 px-3 py-2">BOOK YOUR TICKET</button>
                                </div>
                            </div>
                            <div className='position-rel'>
                                <img src={require('../../assets/img/Landing_Page/image_1.png').default} alt="PRINCE_RA" className="img-fluid" />
                                <div className='date-box px-2'>
                                    <h3 className='text-dark ff-grotesk-semibold mb-0'>21</h3>
                                    <p className='text-dark ff-grotesk-semibold mb-0'>NOV</p>
                                </div>
                                <div className='book-ticket'>
                                    <p className='text-white text-uppercase ff-satoshi-bold'>when we were young</p>
                                    <p className='d-flex align-items-center text-white text-uppercase ff-satoshi-regular mb-0'><MdLocationOn color='white' size={25} /> <span>wembley stadium uk</span></p>
                                    <button className="button-50 ff-GT-bold mt-3 mx-0 px-3 py-2">BOOK YOUR TICKET</button>
                                </div>
                            </div>
                            <div className='position-rel'>
                                <img src={require('../../assets/img/Landing_Page/image_2.png').default} alt="PRINCE_RA" className="img-fluid" />
                                <div className='date-box px-2'>
                                    <h3 className='text-dark ff-grotesk-semibold mb-0'>21</h3>
                                    <p className='text-dark ff-grotesk-semibold mb-0'>NOV</p>
                                </div>
                                <div className='book-ticket'>
                                    <p className='text-white text-uppercase ff-satoshi-bold'>when we were young</p>
                                    <p className='d-flex align-items-center text-white text-uppercase ff-satoshi-regular mb-0'><MdLocationOn color='white' size={25} /> <span>wembley stadium uk</span></p>
                                    <button className="button-50 ff-GT-bold mt-3 mx-0 px-3 py-2">BOOK YOUR TICKET</button>
                                </div>
                            </div>
                            <div className='position-rel'>
                                <img src={require('../../assets/img/Landing_Page/image_1.png').default} alt="PRINCE_RA" className="img-fluid" />
                                <div className='date-box px-2'>
                                    <h3 className='text-dark ff-grotesk-semibold mb-0'>21</h3>
                                    <p className='text-dark ff-grotesk-semibold mb-0'>NOV</p>
                                </div>
                                <div className='book-ticket'>
                                    <p className='text-white text-uppercase ff-satoshi-bold'>when we were young</p>
                                    <p className='d-flex align-items-center text-white text-uppercase ff-satoshi-regular mb-0'><MdLocationOn color='white' size={25} /> <span>wembley stadium uk</span></p>
                                    <button className="button-50 ff-GT-bold mt-3 mx-0 px-3 py-2">BOOK YOUR TICKET</button>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                    <div className='position-coming-text text-center'>
                        <h2 className='text-white sub-header text-uppercase mb-0'>COMING SOON</h2>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Recent_Concert