
import Footer from "components/Footer/Footer";
import React from "react";
// core components
import Banner from "./Banner";
import FAQ from "./FAQ";
import Health_Wealth from "./Health_Wealth";
import Listen_music from "./Listen_music";
import NFT_Collection from "./NFT_Collection";
import Recent_Concert from "./Recent_Concert";
import Roadmap from "./Roadmap";
import Deep_Rooted from "./Deep_Rooted";
import Beanoredh_Studios from "./Beanoredh_Studios";


export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };

  }, []);
  return (
    <>
      <div className="wrapper" >
        <Banner />
        <NFT_Collection />
        <Listen_music />
        <Recent_Concert />
        <Health_Wealth />
        <Deep_Rooted />
        <Beanoredh_Studios />
        {/* <Roadmap /> */}
        <FAQ />
        <Footer />
      </div>
    </>
  );
}
